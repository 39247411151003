import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';



const FooterText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const PageFooter = () => {
  return (
    <footer className="footer">
   
      <FooterText variant="body2">
      SREE DHANALAKSHMI ENTERPRISES PRIVATE LIMITED
      </FooterText>
      <FooterText variant="body2">CIN : U74999KL2022PTC073719.</FooterText> 
      <FooterText variant="body2">
        &copy; 2024-25 Dhanalakshmi Enterprises(p)Ltd. All rights reserved.
      </FooterText>
      <FooterText variant="body2">
        Powered By: IT Department Dhanalakshmi Group
      </FooterText>
    </footer>
  );

};

export default PageFooter;
