import React from 'react';
import styled, { keyframes } from 'styled-components';

// Popup animation
const popup = keyframes`
  0%   { transform: scale(0); opacity: 0; }
  50%  { transform: scale(1.2); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

// Styled components
const BouncingTextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;  // Stack text vertically
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-family: 'Arial Black', sans-serif;
  letter-spacing: 0.1em;  // Adjust letter spacing

  @media (max-width: 600px) {
    letter-spacing: 0.04em;  // Adjust letter spacing for smaller screens
  }
`;

const LineContainer = styled.div`
  display: flex;
  flex-wrap: wrap;  // Ensure wrapping for smaller screens
  justify-content: center;
  font-size: 3vw;  // Responsive font size
  line-height: 1.2;  // Adjust line spacing

  @media (max-width: 600px) {
    font-size: 5vw;  // Larger font size for smaller screens
    line-height: 1.9;  // Adjust line spacing for smaller screens
  }
`;

const Letter = styled.div`
  
  color: #f60606;
  text-shadow: 0 3px #ffffff, 0 5px #ffffff, 0 7px #ffffff;
  animation: ${popup} 6s ease-out forwards;
  animation-iteration-count: infinite;
  margin: 0 0.02em;  // Adjust spacing between letters
  // Apply staggered animation delay to each letter
  &:nth-child(1) { animation-delay: 0s; }
  &:nth-child(2) { animation-delay: 0.1s; }
  &:nth-child(3) { animation-delay: 0.2s; }
  &:nth-child(4) { animation-delay: 0.3s; }
  &:nth-child(5) { animation-delay: 0.4s; }
  &:nth-child(6) { animation-delay: 0.5s; }

    @media (max-width: 600px) {
    color: #d6175c;
  text-shadow: 0 5px #0000, 0 5px #0000, 0 1px #0000;
  animation: ${popup} 5s ease-out forwards;
  animation-iteration-count: infinite;
  margin: 0 0.02em;  // Adjust spacing between letters
  // Apply staggered animation delay to each letter
  &:nth-child(1) { animation-delay: 0s; }
  &:nth-child(2) { animation-delay: 0s; }
  &:nth-child(3) { animation-delay: 0s; }
  &:nth-child(4) { animation-delay: 0s; }
  &:nth-child(5) { animation-delay: 0s; }
  &:nth-child(6) { animation-delay: 0s; }
  }
`;

const Space = styled.div`
  width: 0.25em;  // Adjust the width of the space
  display: inline-block;  // Ensure proper spacing
`;

const BouncingText = () => {
  return (
    <BouncingTextContainer>
      <LineContainer>
        <Letter>S</Letter>
        <Letter>R</Letter>
        <Letter>E</Letter>
        <Letter>E</Letter>
        <Space />
        <Letter>D</Letter>
        <Letter>H</Letter>
        <Letter>A</Letter>
        <Letter>N</Letter>
        <Letter>A</Letter>
        <Letter>L</Letter>
        <Letter>A</Letter>
        <Letter>K</Letter>
        <Letter>S</Letter>
        <Letter>H</Letter>
        <Letter>M</Letter>
        <Letter>I</Letter>
        <Space />
      </LineContainer>
      {/* <LineContainer>
        <Letter>2</Letter>
        <Letter>0</Letter>
        <Letter>2</Letter>
        <Letter>4</Letter>
      </LineContainer> */}
    </BouncingTextContainer>
  );
};

export default BouncingText;
