import React, { useState, useEffect } from 'react';
import { Box,Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dhanLogo from "../assets/img/dhan_logo.jpg";  // Import the logo image
import  StyledTypography  from '../components/StyledTypography'; // Import the StyledTypography
import DecorativeLamp from '../components/DecorativeLamp';
import GradientAnimation from '../components/GradientAnimation';
import { keyframes } from '@mui/system';

const popUp = keyframes`
  text-shadow: 0 3px #1B2845, 0 5px #1B2845, 0 7px #1B2845;
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const LoaderContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1300,
  overflow: 'hidden',
  background: 'linear-gradient(180deg, #ffffff 100%, #800000 10%)',  // White to burgundy gradient
}));

const textContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  textalign: 'center',
  alignItems: 'baseline',
  justifyContent: 'center',
  width: '50%',
  height: '50%',
  margin: 0,
  color:'#f60606',
  fontWeight: '900!important',
  fontSize: '4vw!important',
  textTransform: 'uppercase',
  fontFamily: 'Georgia',
  letterSpacing: '0.05em', // Adjust letter spacing
  background: 'linear-gradient(to left, #ff1b00, #e4f712fa)', // White to burgundy gradient
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent', // Ensures text color is transparent to show gradient
  
  animation: `${popUp} 0.8s ease-out 0s infinite` ,
  width: { xs: '80%', sm: '80%', md: '50%' },
  height: { xs: 'auto', md: '50%' },
}));

const Logo = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(5),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0)',  // Center and scale down initially
  width: '30vw',  // Make logo size responsive using viewport width
  height: 'auto',
  zIndex: 1,  // Ensure the logo is above other elements
  transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',  // Apply the pop-out and fade-in effect
  animation: 'popOut 0.6s forwards, fadeIn 1s forwards',  // Combine animations
  '@keyframes popOut': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(5)',  // Start scaled down
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1)',  // Scale to original size
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,  // Start invisible
    },
    '100%': {
      opacity: 1,  // Fade in
    },
  },
  '@media (max-width: 600px)': {  // Responsive size adjustments
    width: '50vw',  // Larger size on smaller screens
  },
}));

const FullPageLoader = () => {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    // Set the timer to hide the loader after 5 seconds
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 5000); // Set duration here (e.g., 5000ms for 5 seconds)

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    
    <LoaderContainer>
   
    {/* <StyledTypography variant="h5" sx={{ position: 'absolute', top: '35%', zIndex: 2 }}>
      Annual Meet 2024
    </StyledTypography> */}
  
  {/* <Box
      sx={{
        display: 'flex',                // Use flexbox layout
        alignItems: 'center',           // Center items vertically
        justifyContent: 'center',       // Center items horizontally
        height: '100vh',                // Full viewport height
      //  paddingLeft:'120px'
        
      }} */}
    
      
      {/* <DecorativeLamp /> */}
    
    {/* <StyledTypography  variant="h2" sx={{ position: 'absolute', top: '25%', zIndex: 2 }}>
              <span>W</span>
              <span>e</span>
              <span>l</span>
              <span>c</span>
              <span>o</span>
              <span>m</span>
              <span>e</span>
              <span> </span>
              <span>t</span>
              <span>o</span>
              <span> </span>
              <span>D</span>
              <span>h</span>
              <span>a</span>
              <span>n</span>
              <span>a</span>
              <span>l</span>
              <span>a</span>
              <span>k</span>
              <span>s</span>
              <span>h</span>
              <span>m</span>
              <span>i</span>
              <span> </span>
              <span>G</span>
              <span>r</span>
              <span>o</span>
              <span>u</span>
              <span>p</span>
            </StyledTypography> */}
            {/* </Box> */}
    {showLogo && (
      <Logo src={dhanLogo} alt="Dhan Logo" />
    )}
    {/* <textContainer>
  Welcome to<br/> Dhanalakshmi Enterprises
  </textContainer> */}
     
  </LoaderContainer>
  
 </>
  );
};

export default FullPageLoader;
