import React from 'react';
import '../assets/css/main.css';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import PageFooter from '../components/PageFooter';
import ContactCard from '../components/ContactCard';
import contactusimg from '../assets/img/contactusimg.jpg';

const MainContainer = styled(Container)(({ theme }) => ({
    marginBottom: theme.spacing(4), // Adjust the spacing value as needed
  }));

const ImageContainer = styled('div')({
  position: 'relative',
  textAlign: 'center',
  color: 'white',
  marginBottom: '16px',
});

const SampleImage = styled('img')({
  width: '100%',
height: 'auto',
  borderRadius: '8px',
  alignContent:'center'
});

const TextOverlay = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '30%',
  fontSize:'auto',
  transform: 'translate(-50%, -50%)',
  color: 'rgba(0, 0, 0, 0.5)',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(1),
  borderRadius: '8px',
  textAlign: 'center',
}));

const Contactus = () => {
  return (
    <>
      <ResponsiveAppBar />
     
        {/* <Container fluid> */}
          <ImageContainer>
            <SampleImage src={contactusimg} alt="Contact Us Image" />
            <TextOverlay variant="h4">Contact Us</TextOverlay>
          </ImageContainer>
          <Typography variant="caption" align="left"></Typography>
        {/* </Container> */}
      <MainContainer>
        <section className="rsvp">
            <h2>Get in Touch</h2>
            <p sx={{color:'#5a4a4a'}}> Welcome to our company! We are committed to providing the best service to our customers.</p>
            <ContactCard />
          </section>
        </MainContainer>
     
      
      <PageFooter />
    </>
  );
};

export default Contactus;
