import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const CardCarousel = [
  {
    label: 'SAMSUNG 21',
    imgPath: 'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    c: '512',
    desc: 'This is a TV of 32 inch, not for you',
  },
  {
    label: 'Bird',
    imgPath: 'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    c: '312',
    desc: 'This is a TV of 32 inch, not for you',
  },
  {
    label: 'Bali, Indonesia',
    imgPath: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
    c: '412',
    desc: 'This is a TV of 32 inch, not for you',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath: 'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
    c: '132',
    desc: 'This is a TV of 32 inch, not for you',
  },
  {
    label: 'Goč, Serbia',
    imgPath: 'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    c: '112',
    desc: 'This is a TV of 32 inch, not for you',
  },
];

const Root = styled('div')(({ theme }) => ({
  margin: '10px 10px',
  display: 'flex',
  flexDirection: 'column',
}));

const Media = styled(CardMedia)({
  height: 200,
});

const Typo = styled(Typography)({
  textAlign: 'center',
});

const MyCard = styled(Card)({
  width: 300,
});

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Cards = () => {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000} // Adjust the speed as needed (3000ms = 3s)
      infinite={true} // Enable infinite looping
    >
      {CardCarousel.map((item, i) => (
        <CardSwipeable key={i} item={item} />
      ))}
    </Carousel>
  );
};

const CardSwipeable = (props) => {
  return (
    <Root>
      <MyCard>
        <CardActionArea>
          <Media image={props.item.imgPath} title={props.item.label} />
          <CardContent>
            <Typo gutterBottom variant="h6" component="h6" sx={{ color: '#d6175c' }}>
              ${props.item.c}
            </Typo>
            <Typo variant="h5" component="h3" sx={{ color: '#d6175c', margin: '6px 0px' }}>
              {props.item.label}
            </Typo>
            <Typo color="textSecondary" component="p">
              {props.item.desc}
            </Typo>
          </CardContent>
        </CardActionArea>
      </MyCard>
    </Root>
  );
};

export default Cards;
