import React, { useEffect, useState } from 'react';
import { startFireworks } from '../assets/js/fireworks';
import "../assets/css/main.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IMG from '../assets/img/sreedhan_slider1.jpg';
import FullPageLoader from '../components/FullPageLoader';
import BouncingText from '../components/BouncingText';
import FireworkEffect from '../components/FireworkEffect';
import StyledTypography from '../components/StyledTypography';
import Container from '@mui/material/Container';
import Header_Box from '../components/HeaderBox'; 
import ImageWithText from '../components/ImageWithText'; 
import ProgramContact from '../components/ProgramContact'; 
import ContactCard from '../components/ContactCard'; 
import Schedule from "../components/Schedule";
import { scheduleData } from './EventData';
import { indigo } from '@mui/material/colors';
import CloudWithLight from '../components/CloudWithLight';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import CardCarousel from '../components/CardCarousel';
import PageFooter from '../components/PageFooter';

const EventDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  color:'#5a4a4a',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false); // Hide loader after data is loaded
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false); // Hide loader even if there's an error
      }
    };
    loadData();
  }, []);

  return (
    <>
      {loading && <FullPageLoader />}
      <div className="header-content">
        <ResponsiveAppBar />
        <Header_Box 
          backgroundImage={IMG} 
          sx={{ 
            position: 'relative', 
            zIndex: 1,
            backgroundPosition: 'center', // Centers the image
            width: '100%', // Ensures the container takes up the full width
            height: 'auto', // Ensures the container takes up the full height
          }}
        >
          <BouncingText />
        </Header_Box>
        <main className="main-content">
          <section className="event-details">
            <Typography variant="h5">Sree Dhanalakshmi Enterprises</Typography>
            <Typography variant="h7">Your Trusted Business Partner.</Typography>
            <EventDetailsContainer>
              <Box>
                <ul>
                  <li>
                    "Welcome to Sree Dhanalakshmi Enterprises, your trusted partner in growth and stability. 
                    We are a dedicated team of professionals committed to providing personalized 
                    solutions to meet your unique needs. We offer a range of services designed to help you achieve your
                    goals. At Sree Dhanalakshmi Enterprises, we pride ourselves on our integrity, reliability, 
                    and customer-centric approach. Let us guide you towards a secure future with our 
                    expert advice and tailored products. Join us and experience the difference of a 
                    company that truly cares about your success."
                  </li>
                </ul>
              </Box>
            </EventDetailsContainer>
          </section>
          <section className="event-details">
            <EventDetailsContainer>
              <ImageWithText /> 
            </EventDetailsContainer>
          </section>
          <section className="event-details">
            <Schedule scheduleData={scheduleData} />
          </section>
          <section className="event-details">
          </section>
        </main>
        <PageFooter />
      </div>
    </>
  );
}

export default Home;
