import React from 'react';
import { Box, Grid, Typography,Button } from '@mui/material';
import VENU_IMG from '../assets/img/dhanHO.jpeg';
import { styled } from '@mui/material/styles';

// Styled component for the Google Map
const GoogleMap = styled('iframe')(({ theme }) => ({
  borderRadius: 2,
  border: 0,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  // position: "absolute"

}));
const MapContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 4,
  minHeight: '270px',
}));

const OverlayButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 1,
}));
const ImageWithText = () => {
  const handleMapRedirect = () => {
    window.open("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1004405.8681664568!2d75.10457214687499!3d10.464323300000025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7f1d52730835f%3A0xb40615f07a9e9699!2sDhanalakshmi%20group%20of%20companies!5e0!3m2!1sen!2sin!4v1730661863036!5m2!1sen!2sin", '_blank');
  };
  return (
    // <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
              <Grid item  xs={12} sm={6} md={6} >
              <Box 
                component="img" 
                src={VENU_IMG}
                alt="Sample Image" 
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 0,
                  marginBottom: 2,
                }} 
              />
              <Typography variant="h6">
              Sree Dhanalakshmi , Thrissur
              </Typography>
             
              
              </Grid>

              
              <Grid sx={{position:"relative",overflow:"hidden",borderRadius: 4,minHeight:"270px"}} item xs={12} sm={6} md={6}>
            {/* /second box */}
              
              <GoogleMap
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1004405.8681664569!2d75.1045721!3d10.4643233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7f1d52730835f%3A0xb40615f07a9e9699!2sDhanalakshmi%20group%20of%20companies!5e0!3m2!1sen!2sin!4v1730691916000!5m2!1sen!2sin"
                        allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                         <OverlayButton
            variant="contained"
            color="primary"
            onClick={handleMapRedirect}
          >
            View on Google Maps
          </OverlayButton>
        
              {/* </Box> */}
              
              </Grid>
              
              {/* </Box> */}
             
    </Grid>
  );
}

export default ImageWithText;
